import React, { useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// Helpers & mock content
import { fireEvent, months, removeTags } from "../../utils/helpers"
import Image from "../../components/Image"
import Chip from "../../components/blog/Chip"
import { TextBlockWrapper } from "../../modules/TextBlock/TextBlock"
import { Helmet } from "react-helmet"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

export const BlogImage = styled.div`
  max-height: 1173px;
  min-height: 40vw;
  @media (max-width: 640px) {
    height: 280px;
  }
`

// markup
const BlogPost = ({ pageContext }) => {
  const reversed = useReverseLanguage()
  const { blogInfo, locale, slug } = pageContext
  const { title, headline, date, image, richText, tags } = blogInfo
  let postDate = date ? new Date(date) : null
  let metaDescription = richText ? removeTags(richText.split("</")[0]) : ""

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  return (
    <div className="relative">
      <Helmet
        htmlAttributes={{
          locale,
          dir: reversed ? "rtl" : "ltr",
        }}
        title={title}
        titleTemplate={`%s | TIER Blog`}
      >
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title + " | TIER Blog"} />
        <meta property="og:description" content={metaDescription} />
        {image[0] && <meta property="og:image" content={image[0].url} />}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        {headline && <meta name="twitter:creator" content={headline} />}
        <meta name="twitter:title" content={title + " | TIER Blog"} />
        <meta name="twitter:description" content={metaDescription} />
        {image[0] && <meta name="twitter:image" content={image[0].url} />}
      </Helmet>
      <div className="pt-28 px-5 sm:px-10 md:px-36 flex flex-col items-center">
        <div className="md:max-w-4xl w-full flex flex-col">
          {tags && (
            <div className="flex mb-4 space-x-4">
              {tags.map(tag => (
                <Chip key={tag.title} text={tag.title} size="large" />
              ))}
            </div>
          )}
          <h4 className={"text-blue font-extended mb-8 " + (useReverseLanguage() ? "text-right" : "text-left")}>{title}</h4>
          <div className="text-gray-400">
            {postDate && <p className="text-n3">{months[postDate.getMonth()] + " " + postDate.getDate() + ", " + postDate.getFullYear()}</p>}
            {headline && <p className="text-n3">By {headline}</p>}
          </div>
        </div>
        {image[0] && (
          <BlogImage className="relative overflow-hidden bg-green-background rounded-2xl my-8 sm:my-10 w-full">
            <Image
              alt="blog"
              src={image[0].url}
              className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full"
            />
          </BlogImage>
        )}
        <TextBlockWrapper className="max-w-4xl w-full my-6 sm:my-10 space-y-8" dangerouslySetInnerHTML={{ __html: richText }} textTheme="dark" />
      </div>
    </div>
  )
}

BlogPost.propTypes = {
  pageContext: PropTypes.shape({
    blogInfo: PropTypes.object,
    footerInfo: PropTypes.object,
    locale: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default BlogPost
