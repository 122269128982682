import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "../../components/containers/Container"

export const TextBlockWrapper = styled.div`
  & h6 {
    line-height: 30px;
    font-weight: normal;
  }

  & h6,
  & p,
  & li {
    color: ${({ textTheme }) => (textTheme === "dark" ? "#4a505e" : "white")};
  }

  & ul {
    padding-left: 20px;
    list-style: initial;
  }

  & li {
    margin-bottom: 6px;
  }

  & li {
    font-size: 20px;
    line-height: 25px;
    color: ${({ textTheme }) => (textTheme ? (textTheme === "light" ? "white" : "#4a505e") : "#4a505e")};
  }

  & h4 {
    font-family: Neue Plak Extended;
    color: ${({ textTheme }) => (textTheme === "dark" ? "#0e1a50" : "white")};
    margin-top: 64px !important;
  }

  & img {
    border-radius: 16px;
  }

  & figcaption {
    color: #4a505e;
    padding-left: 16px;
    margin-top: 10px;
  }

  & blockquote {
    font-size: 24px;
    line-height: 30px;
    font-style: italic;
    color: ${({ textTheme }) => (textTheme ? (textTheme === "light" ? "white" : "#0e1a50") : "#0e1a50")};
    margin-bottom: 12px;

    footer {
      font-size: 14px;
      line-height: 17px;
      font-weight: 100;
      color: #a3a3a3;
    }
  }

  & h1,
  & h2,
  & h3,
  & h5 {
    color: ${({ textTheme }) => (textTheme === "dark" ? "#0e1a50" : "white")};
  }

  & a {
    color: #0050ff;
  }
`

const TextBlock = ({ children, content, id, ...props }) => {
  const { textTheme, color } = content
  const bgColor = color ? `bg-${color}` : "bg-background"

  return (
    <div className={bgColor}>
      <Container id={id} className="flex justify-center">
        <TextBlockWrapper {...props} textTheme={textTheme} className="w-full space-y-8" style={{ maxWidth: 860 }}>
          {children}
        </TextBlockWrapper>
      </Container>
    </div>
  )
}

TextBlock.propTypes = {
  content: PropTypes.object,
  id: PropTypes.string,
}

export default TextBlock
