import React from "react"
import PropTypes from "prop-types"
import CloseIcon from "../icons/CloseIcon"

const Chip = ({ text, color, background, showClosing, noShadow, size = "normal", ...props }) => {
  return (
    <div
      {...props}
      className={"flex items-center justify-center h-7 px-2 -pt-3 pb-1 rounded-md cursor-pointer " + (background || "bg-white")}
      style={{ boxShadow: !noShadow ? "0px 5px 20px rgba(0, 0, 0, 0.2)" : null }}
    >
      <p className={"leading-4 " + (size === "large" ? "text-p3 " : "text-n3 ") + (color || "text-gray-500")}>{text}</p>
      {showClosing && <CloseIcon className="mt-1 ml-2" />}
    </div>
  )
}

Chip.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  showClosing: PropTypes.bool,
  noShadow: PropTypes.bool,
  size: PropTypes.oneOf(["large", "normal"]),
}

export default Chip
