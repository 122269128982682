import React, { useEffect, useRef } from "react"

const Image = ({ Component, className, override, ...props }) => {
  const ref = useRef(null)

  const onLoad = e => {
    e.target.style.opacity = 1
  }

  useEffect(() => {
    if (ref.current?.complete) {
      ref.current.style.opacity = 1
    }
  }, [])

  if (Component) {
    return (
      <Component
        {...props}
        className={"transition-opacity duration-300 " + className}
        ref={ref}
        style={{ opacity: override ? 1 : 0 }}
        onLoad={e => onLoad(e)}
        onError={e => (e.target.style.display = "none")}
      />
    )
  } else {
    return (
      <img
        {...props}
        style={{ opacity: override ? 1 : 0 }}
        className={"transition-opacity duration-300 " + className}
        ref={ref}
        onLoad={e => onLoad(e)}
        onError={e => (e.target.style.display = "none")}
      />
    )
  }
}

export default Image
